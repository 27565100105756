import React from "react";
import image from "web/assets/img/sample2.jpg";

const Hero = () => {
  return (
    <>
      <div className="block-hero">
        <div className="block-hero__left">
          <div className="block-hero__left__inner">
            <div className="block-hero__left__content">
              <div className="richtext">
                <h1>Cabinet de chiropraxie à Bressuire</h1>
                <p>
                  Adrien Rollet, chiropracteur diplômé en 2012, vous reçoit en
                  urgence ou en prévention des TMS (Troubles
                  musculo-squelettiques).{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="block-hero__right">
          <div className="block-hero__right__inner">
            <img src={image} alt="" />
          </div>
        </div>
        <svg class="flt_svg" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <filter id="flt_tag">
              <feGaussianBlur
                in="SourceGraphic"
                stdDeviation="8"
                result="blur"
              />
              <feColorMatrix
                in="blur"
                mode="matrix"
                values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9"
                result="flt_tag"
              />
              <feComposite in="SourceGraphic" in2="flt_tag" operator="atop" />
            </filter>
          </defs>
        </svg>
      </div>
    </>
  );
};

export default Hero;
